<template>
  <div
    class="flex flex-col p-8 justify-between rounded-8 bg-grayscale-10 w-[362px] mx-auto"
  >
    <div class="flex flex-col gap-5">
      <div class="flex items-center gap-4">
        <image-kit
          :path="imagePath"
          width="56"
          height="56"
          :alt="`${proName} profile image`"
          class="rounded-full"
        />
        <div>
          <a
            target="_blank"
            :href="`https://xposuremusic.com/${proSlug}`"
            class="text-[15px] font-bold text-grayscale-96 mb-2 hover:text-accent-orange"
          >
            {{ proName }}
          </a>
          <p class="text-xs text-grayscale-62 mb-4 leading-[140%]">
            {{ worksAt }}
          </p>
        </div>
      </div>
      <div class="text-grayscale-96 leading-[180%]">
        {{ description }}
      </div>
      <router-link target="_blank" :to="`/${proSlug}`" class="text-sm">
        <p
          class="py-2 px-6 bg-transparent border border-grayscale-30 hover:border-grayscale-96 rounded-54 text-13 text-grayscale-96 font-bold flex items-center justify-center w-fit"
        >
          Connect with {{ firstName }}
        </p>
      </router-link>
    </div>
  </div>
</template>

<script setup lang="ts">
import ImageKit from "@/components/imagekit/ImageKit.vue";

const props = defineProps({
  imagePath: {
    type: String,
    required: true,
  },
  proName: {
    type: String,
    required: true,
  },
  worksAt: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    required: true,
  },
  proSlug: {
    type: String,
    required: true,
  },
});

const firstName = computed(() => props.proName.split(" ")[0]);
</script>
