<template>
  <div
    class="flex flex-col w-full max-w-screen relative overflow-x-hidden z-0"
    style="
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    "
  >
    <div
      :class="{
        'overflow-overlay overflow-x-hidden': !isLogged,
      }"
      class="w-full flex flex-col items-center relative"
    >
      <div
        class="w-[70vh] w-full absolute overflow-hidden video-container -mt-[75px] md:mt-0"
      >
        <ClientOnly>
          <video
            ref="headerVideo"
            class="object-cover w-full h-[50vh] video-element"
            autoplay
            loop
            muted
            playsinline
          >
            <source
              src="https://ik.imagekit.io/xposuremusic/landing-page/657a01e5a3a7c428712e18d0_Xposure%20BG%20Video-transcode_6gdz-o1crU.mp4?updatedAt=1702978107641"
            />
          </video>
        </ClientOnly>
      </div>
      <div
        class="z-20 w-full flex flex-col pt-[70px] md:pt-[120px] md:bg-landing bg-no-repeat items-center text-center bg-cover font-medium gap-10 md:gap-20 bg-center pb-0 md:pb-10vh leading-none md:leading-tight"
      >
        <div class="flex flex-col gap-4 items-center">
          <div
            class="text-[32px] md:text-5xl max-w-[370px] md:max-w-full uppercase font-druk-wide italic"
          >
            <h1 class="xposure-title">Xposure Music</h1>
            Where artists <br />
            level up
          </div>
          <h4 class="uppercase my-2 font-bold text-grayscale-96">
            Get the Capital and Connections You Need. No Strings Attached.
          </h4>
        </div>
        <div
          class="flex flex-col md:flex-row justify-center gap-4 w-full px-6 md:pb-10 pb-8"
        >
          <Link href="https://info.xposuremusic.com/funding" target="_self">
            <p
              class="py-2.5 bg-accent-orange hover:bg-accent-orange-hover rounded-54 text-base text-grayscale-7 font-bold flex items-center justify-center w-full md:w-60"
            >
              Get Funding
            </p>
          </Link>
          <router-link to="/industry-pros">
            <p
              class="py-2.5 bg-transparent border border-grayscale-30 hover:border-grayscale-96 rounded-54 text-base text-grayscale-96 font-bold flex items-center justify-center w-full md:w-60"
            >
              Connect with Pros
            </p>
          </router-link>
        </div>
      </div>

      <div
        class="relative h-[675px] w-full md:w-[1120px] mb-[220px] md:mb-[180px] mt-[100px] md:mt-0"
      >
        <div
          class="absolute w-full md:w-[675px] z-10 px-6 md:px-10 py-10 bg-cover bg-[50%_0] relative bg-[url('https://ik.imagekit.io/xposuremusic/landing-page/Funding-BG_1vDLEczA7.webp?updatedAt=1702982995421')]"
        >
          <div class="flex-col">
            <h3
              class="my-10 font-druk-wide text-grayscale-96 uppercase text-[32px] md:text-[40px] italic"
              data-aos="fade-up"
            >
              Funding
            </h3>
            <div class="flex flex-col gap-10">
              <div class="max-w-[300px] flex flex-col gap-10">
                <div data-aos="fade-up">
                  <h4 class="uppercase my-2 font-bold text-grayscale-96">
                    Get paid. No hassle.
                  </h4>
                  <span class="text-sm span-color"
                    >Unlock funds against your existing tracks. No interference
                    with your new releases.
                  </span>
                </div>
                <div data-aos="fade-up">
                  <h4 class="uppercase my-2 font-bold text-grayscale-96">
                    Artists first
                  </h4>
                  <span class="text-sm span-color"
                    >Flexible deals. No sneaky clauses. You maintain complete
                    control over how you spend, promote, and distribute.</span
                  >
                </div>
                <div data-aos="fade-up">
                  <h4 class="uppercase my-2 font-bold text-grayscale-96">
                    Eligibility
                  </h4>
                  <span class="text-sm span-color"
                    >Got at least 30,000 monthly listeners? You're likely a
                    candidate. We're flexible—show us your earnings, and we'll
                    talk.</span
                  >
                </div>
              </div>

              <Link
                href="https://info.xposuremusic.com/funding"
                class="mt-8 w-full md:w-fit"
                target="_self"
                data-aos="fade-up"
              >
                <p
                  class="px-8 py-3 bg-accent-orange hover:bg-accent-orange-hover rounded-54 text-base text-grayscale-7 font-bold flex items-center justify-center w-full md:w-fit"
                >
                  Apply For Funding
                </p>
              </Link>
            </div>
            <img
              alt="keep-ownership"
              class="w-[105px] h-[105px] absolute bottom-[32px] right-[48px] hidden md:block"
              src="https://ik.imagekit.io/xposuremusic/landing-page/657b169a16dacda4f46b462f_Sticker-min_MNKVMYu8b.webp?updatedAt=1703676285878"
            />
          </div>
        </div>
        <div
          class="absolute p-4 top-[490px] md:top-3 md:left-[400px] h-[650px] w-full md:w-[650px]"
        >
          <img
            alt="vinyl"
            class="rounded-[50%] rotating-vinyl"
            src="https://ik.imagekit.io/xposuremusic/landing-page/disk_GIrsUY0xVG.webp?updatedAt=1702983728801"
          />
        </div>
      </div>

      <!--      MENTORSHIP FROM THE BEST-->
      <div class="flex flex-col-reverse md:flex-row gap-[90px]">
        <div class="relative w-content md:w-[450px] h-[600px]">
          <img
            alt="xposure-faded"
            class="absolute z-0 pointer-events-none w-[1000px] h-[1000px] max-w-[1000px] -left-[250px] -top-[250px]"
            src="https://ik.imagekit.io/xposuremusic/landing-page/xposure-fade-min_zHpa3HIDI.png?updatedAt=1703088188320"
          />
          <div class="mx-auto">
            <div
              v-for="(mentor, index) of mentorshipData"
              class="absolute left-5 md:left-0 top-0 photo-frame opacity-0 z-0 w-[90%] max-w-[450px] md:w-[450px] px-10"
              :class="{
                'opacity-100': index === currentFrame,
                'z-10': index === currentFrame,
                'rotate-1': index % 2 === 0,
                'rotate-2': index % 2 === 1,
              }"
            >
              <img
                alt="xposure-mentor"
                class="w-[419px] h-auto"
                :src="mentor.src"
              />
              <div class="photo-frame-copy">
                {{ mentor.copy1 }}
                <span class="photo-frame-subject">{{ mentor.subject }}</span>
                {{ mentor.copy2 }}
              </div>
              <div class="flex justify-center">
                <router-link :to="`/${mentor.connectURL}`" class="w-auto">
                  <p
                    class="py-1 px-4 rounded-54 text-13 font-bold flex items-center justify-center w-fit bg-transparent text-grayscale-7 hover:bg-grayscale-95 border border-grayscale-30"
                  >
                    Connect with
                    {{ mentor.connectWith }}
                  </p>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full md:w-[515px] text-center md:text-start px-6 md:px-0">
          <h3
            class="mt-8 mb-16 font-druk-wide text-grayscale-96 text-[32px] md:text-[40px] leading-none italic font-druk-wide uppercase"
            data-aos="fade-up"
          >
            Mentorship <br />
            from the best
          </h3>
          <div class="flex flex-col gap-10">
            <div v-for="el of mentorshipRightElementData.elements">
              <div
                class="uppercase text-grayscale-96 font-semibold"
                data-aos="fade-up"
              >
                {{ el.subtitle }}
              </div>
              <div class="span-color text-sm" data-aos="fade-up">
                {{ el.description }}
              </div>
            </div>

            <router-link
              to="/industry-pros"
              class="mt-10 self-center md:self-start"
              data-aos="fade-up"
            >
              <p
                class="px-8 py-3 bg-accent-orange hover:bg-accent-orange-hover rounded-54 text-base text-grayscale-7 font-bold flex items-center justify-center w-fit"
              >
                Meet The Pros
              </p>
            </router-link>
          </div>
        </div>
      </div>

      <div
        class="relative mt-[70px] md:mt-[140px] w-full h-[110px] md:h-[180px]"
      >
        <div class="logos-overlay">
          <Splide
            :extensions="{ AutoScroll }"
            :options="{
              type: 'loop',
              arrows: false,
              gap: '0rem',
              autoWidth: true,
              pagination: false,
              autoScroll: { speed: 0.5 },
            }"
          >
            <SplideSlide v-for="company of companiesLogos">
              <img
                alt="partner-company-logo"
                class="w-[110px] md:w-[140px] h-[110px] md:h-[140px] md:m-10"
                :src="company"
              />
            </SplideSlide>
          </Splide>
        </div>
      </div>

      <ClientOnly>
        <div
          class="flex gap-4 mt-[70px] md:mt-[140px] w-full md:w-fit px-6 md:px-0"
        >
          <Splide
            class="w-full md:w-fit"
            :options="{
              type: 'slide',
              arrows: false,
              gap: '24px',
              autoWidth: true,
              pagination: false,
            }"
          >
            <SplideSlide v-for="(proVideo, index) of prosVideos">
              <div class="flex flex-col gap-4 w-[200px] text-sm">
                <div class="h-[280px] relative" data-aos="fade-up">
                  <video
                    :id="`pro-video-${index}`"
                    class="rounded-12"
                    autoplay
                    muted
                    loop
                    playsinline
                  >
                    <source :src="proVideo.video" />
                  </video>
                  <span
                    @click="muteVideo(index)"
                    class="cursor-pointer absolute top-0 left-0 w-full h-full bg-right-top bg-no-repeat"
                    :class="{
                      muted: unmutedVideo !== index,
                      'not-muted': unmutedVideo === index,
                    }"
                  ></span>
                </div>
                <div class="flex gap-2" data-aos="fade-up">
                  <span class="font-druk-wide">{{ proVideo.name }}</span>
                  <img
                    alt="verified-pro"
                    src="https://ik.imagekit.io/xposuremusic/landing-page/656dc8828f522df4be2c95c6_Star%20Checked%20filled_OKzzOyn9W.svg?updatedAt=1703677839942"
                  />
                </div>
                <div data-aos="fade-up">{{ proVideo.description }}</div>
                <router-link
                  :to="`/${proVideo.connectURL}`"
                  class="text-sm"
                  data-aos="fade-up"
                >
                  <p
                    class="py-2 px-6 bg-transparent border border-grayscale-30 hover:border-grayscale-96 rounded-54 text-13 text-grayscale-96 font-bold flex items-center justify-center w-fit"
                  >
                    Connect
                  </p>
                </router-link>
              </div>
            </SplideSlide>
          </Splide>
        </div>
      </ClientOnly>

      <router-link
        to="/industry-pros"
        class="my-20 px-6 w-full md:w-fit"
        data-aos="fade-up"
      >
        <p
          class="px-8 py-3 bg-accent-orange hover:bg-accent-orange-hover rounded-54 text-base text-grayscale-7 font-bold flex items-center justify-center"
        >
          Explore Music Pros
        </p>
      </router-link>

      <div class="mb-[200px] relative w-full bg-black">
        <div class="relative w-fit mx-auto">
          <img
            alt="music-studio"
            src="https://ik.imagekit.io/xposuremusic/landing-page/studio_5o3GO_bkH.jpg?updatedAt=1703163367711"
          />
          <img
            alt="xposure-artist"
            class="absolute z-10 top-0 left-0"
            src="https://ik.imagekit.io/xposuremusic/landing-page/the-artist_1W1pnAU7T.png?updatedAt=1703163375616"
          />
          <div
            class="absolute z-20 -bottom-[17.5rem] md:-bottom-[7.5rem] inset-x-0 text-center text-grayscale-96"
          >
            <h2
              class="uppercase mb-16 font-druk-wide text-[32px] md:text-[64px] italic leading-none"
              data-aos="fade-up"
            >
              Artist<br />Showcase
            </h2>
            <h3
              class="uppercase mb-6 text-base md:text-xl leading-[140%]"
              data-aos="fade-up"
            >
              Meet the artists who've found<br />their groove with Xposure
            </h3>
            <div
              class="my-4 span-color text-sm md:text-base leading-[180%] px-6 md:max-w-[600px] md:mx-auto"
              data-aos="fade-up"
            >
              From emerging talents to established names, these artists have
              unlocked new career heights through Xposure's targeted funding and
              dynamic networking opportunities.
            </div>
          </div>
        </div>
        <div class="artist-showcase" />
      </div>

      <Splide
        class="mt-[100px] md:mt-0 w-full"
        :extensions="{ AutoScroll }"
        :options="{
          type: 'loop',
          arrows: false,
          gap: '24px',
          autoWidth: true,
          pagination: false,
          autoScroll: { speed: 0.5 },
        }"
      >
        <SplideSlide v-for="spotifyPro of prosSpotifyCarousel">
          <div class="w-[270px] flex flex-col">
            <img
              alt="xposure-artist-on-spotifu"
              class="w-[270px] h-[270px] rounded-[16px]"
              :src="spotifyPro.src"
            />
            <Link
              class="mt-10 mb-4 text-20 text-grayscale-96 hover:text-accent-orange-hover"
              :href="spotifyPro.spotifyURL"
              target="_blank"
            >
              {{ spotifyPro.name }}
            </Link>
            <div class="flex text-spotify-green text-sm">
              <img
                alt="spotify-logo"
                class="w-4 h-4 mr-[10px] my-auto"
                src="https://ik.imagekit.io/xposuremusic/landing-page/spotify_NQlTcC8Lf.svg?updatedAt=1703169544857"
              />
              {{ spotifyPro.listeners }} monthly listeners
            </div>
          </div>
        </SplideSlide>
      </Splide>
      <!--      </div>-->

      <!-- WORDS FROM OUR COMMUNITY-->
      <h2
        class="uppercase mt-20 mb-16 text-center px-8 md:px-0 text-[32px] md:text-[40px] text-grayscale-96 italic font-druk-wide leading-none md:max-w-[530px]"
      >
        Words from our community
      </h2>
      <div class="flex gap-4 text-grayscale-96 text-sm">
        <button
          class="uppercase px-4 py-2"
          :class="{ 'active-tab': wordsFromCommunityVisible === 'artists' }"
          @click="wordsFromCommunityVisible = 'artists'"
        >
          Artists
        </button>
        <button
          class="uppercase px-4 py-2"
          :class="{ 'active-tab': wordsFromCommunityVisible === 'pros' }"
          @click="wordsFromCommunityVisible = 'pros'"
        >
          Pros
        </button>
      </div>

      <div class="mt-6 md:mt-10 relative overflow-hidden">
        <swiper
          v-if="wordsFromCommunityVisible === 'artists'"
          class="w-[1180px]"
          passiveListeners
          :slidesPerView="3"
          :spaceBetween="16"
          :navigation="{
            nextEl: '.swiper-next',
            prevEl: '.swiper-prev',
          }"
          :loop="true"
          :modules="[Navigation]"
        >
          <swiper-slide v-for="testimonial of wordsFromOurCommunity.artists">
            <ArtistTestimonial
              :user-name="testimonial.name"
              :userProfileImagePath="testimonial.src"
              :pro-name="testimonial.connectedWith"
              :pro-slug="testimonial.proSlug"
              :rating="testimonial.rating"
              :description="testimonial.description"
            />
          </swiper-slide>
        </swiper>
        <swiper
          v-else
          class="w-[1180px]"
          passiveListeners
          :slidesPerView="3"
          :spaceBetween="16"
          :navigation="{
            nextEl: '.swiper-next',
            prevEl: '.swiper-prev',
          }"
          :loop="true"
          :modules="[Navigation]"
        >
          <swiper-slide v-for="testimonial of wordsFromOurCommunity.pros">
            <ProTestimonial
              :pro-name="testimonial.name"
              :pro-slug="testimonial.slug"
              :image-path="testimonial.src"
              :works-at="testimonial.worksAt"
              :description="testimonial.description"
            />
          </swiper-slide>
        </swiper>
      </div>

      <div class="flex gap-8 px-6 mt-8">
        <button
          class="swiper-prev h-10 w-10 bg-grayscale-63 rounded-full flex justify-center items-center"
        >
          <img
            src="@/assets/icons/arrow-down-dark.svg"
            alt="arrow-down"
            class="w-4 h-4 transform rotate-90 mr-px"
          />
        </button>
        <button
          class="swiper-next h-10 w-10 bg-grayscale-63 rounded-full flex justify-center items-center"
        >
          <img
            src="@/assets/icons/arrow-down-dark.svg"
            alt="arrow-down"
            class="w-4 h-4 transform -rotate-90 ml-px"
          />
        </button>
      </div>

      <!--      IN THE PRESS-->
      <h2
        class="uppercase text-32 md:text-64 leading-none my-[100px] font-druk-wide italic text-center md:text-start"
        data-aos="fade-up"
      >
        In the press
      </h2>
      <div class="mb-[200px]">
        <div class="flex flex-col md:flex-row gap-10 relative">
          <div
            class="z-10 w-[375px] md:w-[460px] h-[580px] rounded-[16px] bg-grayscale-10 overflow-hidden relative md:-rotate-[5deg]"
          >
            <div class="flex flex-col gap-12 p-6">
              <div class="flex justify-between">
                <img
                  alt="variety-logo"
                  src="https://ik.imagekit.io/xposuremusic/landing-page/variety_8DPcIgeFT.svg?updatedAt=1703246415411"
                />

                <Link
                  href="https://variety.com/2022/digital/news/xposure-music-artist-development-professionals-1235357063/"
                >
                  <div
                    class="px-8 py-2 bg-accent-orange hover:bg-accent-orange-hover rounded-54 text-sm text-grayscale-7 font-bold flex items-center justify-center w-fit"
                  >
                    Read
                    <p class="hidden md:block ml-1">Article</p>
                  </div>
                </Link>
              </div>
              <span
                class="absolute z-10 top-[100px] uppercase text-base md:text-20 text-grayscale-96 font-montserrat leading-[120%] pr-8"
                >“Artist Development Platform Xposure Aims to Unlock a ‘New
                Front Door’ to the Music Business”</span
              >
            </div>
            <img
              alt="in-the-press-card"
              class="h-[460px] absolute bottom-0"
              src="https://ik.imagekit.io/xposuremusic/landing-page/in-the-press-card-1_80EqBLUJ1.webp?updatedAt=1703246779570"
            />
          </div>
          <div
            class="z-10 w-full md:w-[460px] h-[580px] rounded-[16px] bg-grayscale-10 overflow-hidden relative md:rotate-[5deg]"
          >
            <div class="flex flex-col gap-12 p-6">
              <div class="flex justify-between">
                <img
                  alt="musically-logo"
                  src="https://ik.imagekit.io/xposuremusic/landing-page/musically_4GWiMJQDJ.svg?updatedAt=1703246415456"
                />
                <Link
                  href="https://musically.com/2022/08/12/xposure-music-opens-up-its-new-feedback-service-for-artists/"
                >
                  <div
                    class="px-8 py-2 bg-accent-orange hover:bg-accent-orange-hover rounded-54 text-sm text-grayscale-7 font-bold flex items-center justify-center w-fit"
                  >
                    Read
                    <p class="hidden md:block ml-1">Article</p>
                  </div>
                </Link>
              </div>
              <span
                class="absolute z-10 top-[100px] uppercase text-base md:text-20 text-grayscale-96 font-montserrat leading-[120%] pr-8"
                >“XPOSURE MUSIC OPENS UP ITS NEW FEEDBACK SERVICE FOR
                ARTISTS”</span
              >
            </div>
            <img
              alt="in-the-press-card-2"
              class="h-[460px] absolute bottom-0"
              src="https://ik.imagekit.io/xposuremusic/landing-page/in-the-press-card-2_rGiiEGw8b.webp?updatedAt=1703246779487"
            />
          </div>

          <img
            alt="xposure-logo"
            class="absolute top-[10%] left-[22%] w-[500px] h-[500px] blur-[50px] z-0"
            src="https://ik.imagekit.io/xposuremusic/landing-page/xposure_iWCM7fn4v.svg?updatedAt=1703259173714"
          />
        </div>
      </div>

      <!--      GOT QUESTIONS?-->
      <div class="px-5 h-[500px] md:h-[650px] w-full">
        <div
          class="flex rounded-[24px] pink-bg py-16 md:py-[120px] px-4 md:px-8 padding: 120px 32px; h-full relative w-full justify-center overflow-hidden"
        >
          <div class="footer-glow" />
          <div
            class="flex text-woodsmoke1 text-center flex-col max-w-[580px] z-10"
          >
            <h2
              class="uppercase text-2xl md:text-40 leading-[110%] mb-16 font-druk-wide italic"
              data-aos="fade-up"
            >
              Got questions?
            </h2>
            <div class="text-xl md:text-28" data-aos="fade-up">
              Feel free to reach out anytime.
            </div>
            <div class="text-xl md:text-28" data-aos="fade-up">
              We're here to assist with funding details, professional selection,
              and much more.
            </div>
            <div
              class="w-[140px] h-[1px] bg-grayscale-13 self-center my-6"
              data-aos="fade-up"
            ></div>
            <div
              class="flex gap-1 self-center font-semibold text-sm mb-16"
              data-aos="fade-up"
            >
              <img
                alt="connections"
                src="https://ik.imagekit.io/xposuremusic/landing-page/6570d96c878345a7dae8f233_Connections_p-kqE-RfS.svg?updatedAt=1703620085740"
              />
              <div class="self-center">
                We typically respond within 24 hours
              </div>
            </div>

            <Link
              href="https://support.xposuremusic.com/knowledge/kb-tickets/new"
              class="self-center"
              data-aos="fade-up"
            >
              <p
                class="px-6 py-2 bg-grayscale-7 text-grayscale-96 hover:bg-transparent hover:text-grayscale-7 border border-transparent box-border hover:border-grayscale-7 rounded-54 font-bold flex items-center justify-center w-fit"
              >
                Contact Us
              </p>
            </Link>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  </div>
</template>
<script setup lang="ts">
import { useAuthenticationStore } from "@/store/authentication";
import Link from "@/components/layout/buttons/Link.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/mousewheel";
import "swiper/css/keyboard";
import ArtistTestimonial from "@/components/ArtistTestimonial.vue";
import ProTestimonial from "@/components/ProTestimonial.vue";
import Footer from "@/components/footers/Footer.vue";
import "@splidejs/vue-splide/css";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import constants from "@/utils/constants";
import { Splide, SplideSlide } from "@splidejs/vue-splide";

const authStore = useAuthenticationStore();
const isLogged = computed(() => Boolean(authStore.authUser?.authToken));
const wordsFromCommunityVisible = ref("artists");
const router = useRouter();
const unmutedVideo = ref(-1);
const mentorshipData = constants.mentorshipData;
const mentorshipRightElementData = constants.mentorshipRightElementData;
const companiesLogos = constants.companiesLogos;
const prosVideos = constants.prosVideos;
const prosSpotifyCarousel = constants.prosSpotifyCarousel;
const wordsFromOurCommunity = constants.wordsFromOurCommunity;

const currentFrame = ref(0);

const changeFrame = setInterval(() => {
  currentFrame.value =
    currentFrame.value + 1 === mentorshipData.length
      ? 0
      : currentFrame.value + 1;
}, 5000);

const muteVideo = (videoIndex: number) => {
  if (videoIndex === unmutedVideo.value) {
    const video = document.getElementById(`pro-video-${videoIndex}`);
    if (video) {
      video.muted = true;
    }
    unmutedVideo.value = -1;
  } else {
    if (unmutedVideo.value > -1) {
      const video = document.getElementById(`pro-video-${unmutedVideo.value}`);
      if (video) {
        video.muted = true;
      }
    }
    unmutedVideo.value = videoIndex;
    const newVideo = document.getElementById(`pro-video-${videoIndex}`);
    if (newVideo) {
      newVideo.muted = false;
    }
  }
};
</script>
<style scoped>
.header-video {
  background-color: transparent !important;
}

.photo-frame {
  @apply bg-grayscale-96;
  @apply px-4;
  @apply pt-4;
  @apply pb-8;
  transition: opacity 1000ms cubic-bezier(0.25, 1, 0.5, 1) 0s;
}

.rotate-1 {
  transform: rotate(3deg);
}

.rotate-2 {
  transform: rotate(-2deg);
}

.photo-frame-button {
  color: #101011;
  font-size: 13px !important;
}

.photo {
  width: 419px;
  height: 419px;
}

.photo-frame-copy {
  @apply uppercase;
  @apply text-grayscale-50;
  @apply text-sm;
  @apply my-6;
  @apply text-center;
}

.photo-frame-subject {
  @apply text-grayscale-2;
  @apply font-bold;
}

.carousel-padding {
  padding-left: 24px;
  padding-right: 24px;
}

@media (min-width: 768px) {
  .carousel-padding {
    padding-left: calc((100vw - 1152px) / 2);
    padding-right: calc((100vw - 1152px) / 2);
  }
}

.bg-blur {
  box-shadow: inset 0px -80px 40px -60px #050505;
}

.swiper-slide {
  width: auto;
}

.pink-bg {
  background-image: url("https://ik.imagekit.io/xposuremusic/landing-page/6570db5134100fae892b25f3_bg_DMKeIsXqr.svg?updatedAt=1703274260485"),
    linear-gradient(80deg, #ef9cff 16%, #ff82a3 59%);
  background-position: -4px -4px, 0 0;
  background-repeat: no-repeat;
  background-size: 28%, auto;
}

.footer-glow {
  z-index: 0;
  width: 500px;
  height: 500px;
  filter: blur(50px);
  background-image: url(https://ik.imagekit.io/xposuremusic/landing-page/xposure_iWCM7fn4v.svg?updatedAt=1703259173714);
  background-position: 50%;
  background-size: cover;
  position: absolute;
  top: 45%;
  bottom: auto;
  left: 50%;
  right: auto;
  pointer-events: none;
}

.xposure-title {
  color: rgba(17, 17, 17, 0);
  -webkit-text-stroke-color: whitesmoke;
  -webkit-text-stroke-width: 1px;
}

.span-color {
  color: #a0a0b1;
}

.active-tab {
  font-weight: 600;
  border-bottom: 1px solid #ffff51;
}

.video-container {
  height: 70vh;
  background-image: url(https://ik.imagekit.io/xposuremusic/landing-page/629df7ad73d10128e4311cf0_bg-1.1.%203_FIvZWzCy-.png?updatedAt=1703754457383),
    linear-gradient(rgba(5, 5, 5, 0.2), #050505 79%);
  background-position: 0 0, 0 0;
  background-size: auto, auto;
  justify-content: center;
  align-items: center;
  padding-top: 120px;
  padding-bottom: 64px;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: 0%;
}

.video-element {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -100;
  background-position: 50%;
  background-size: cover;
  margin: auto;
  position: absolute;
  top: -100%;
  bottom: -100%;
  left: -100%;
  right: -100%;
}

.artist-showcase {
  background-image: url(https://ik.imagekit.io/xposuremusic/landing-page/629df7ad73d10128e4311cf0_bg-1.1.%203_FIvZWzCy-.png?updatedAt=1703754457383),
    linear-gradient(rgba(0, 0, 0, 0) 65%, #050505);
  background-position: 0 0, 0 0;
  background-size: auto, auto;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.muted {
  background-image: url("https://ik.imagekit.io/xposuremusic/landing-page/656e33050de791b0e051a1a5_Unmute_Q2cToCMbI.svg?updatedAt=1703772446424");
}

.not-muted {
  background-image: url("https://ik.imagekit.io/xposuremusic/landing-page/656e36e008c5889448260f6b_Mute_OTvmIUnO5.svg?updatedAt=1703772446509");
}

.logos-overlay {
  z-index: 10;
  background-image: url("https://ik.imagekit.io/xposuremusic/landing-page/629df7ad73d10128e4311cf0_bg-1.1.%203_eRYpTPUzy.png?updatedAt=1703861405766");
  background-position: 50%;
  background-size: auto;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rotating-vinyl {
  animation: rotate 14s linear infinite;
}
</style>
